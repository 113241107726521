/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 
 import Header from "./header"
 import "./layout.css"
 
 const Layout = ({ children, handleShow, openModal, business }) => {
 
 
   return (
     <>
       <div  className="mx-auto">
        <Header handleShow={handleShow} openModal={openModal} />
          <main>{children}</main>
          <footer className="container-default flex sm:flex-row py-10 justify-between">
          <p className="">{`© ${business}`}</p>
          <p>Made with <a className="font-bold" href="https://www.tryevergrow.com" target="blank">Evergrow</a></p>

          </footer>
       </div>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout